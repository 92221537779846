import { map, range } from "lodash-es"

const MAX_MINUTES = 60 * 30
const SCHEDULE_INTERVAL_IN_MINUTES = 30
const MINUTES_RANGE = range(0, MAX_MINUTES + 30, SCHEDULE_INTERVAL_IN_MINUTES)

function padToTwoDigits(num) {
  return num.toString().padStart(2, "0")
}

const getTimeLabel = totalMinutes => {
  const hours = Math.floor(totalMinutes / 60)
  const minutes = totalMinutes % 60

  return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`
}

export const generateTimeRange = () => {
  return map(MINUTES_RANGE, minutes => ({ minutes, label: getTimeLabel(minutes) }))
}
